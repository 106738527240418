<!--
 * @author: JJ
 * @since: 2024-07-16
 * ProductView.vue
-->
<template>
    <div class="container bg-f6">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <!-- <div class="colf fs50 lh60">{{ $t('headerTabs.tab1') }}</div>
                            <div class="fs24 lh28 colf mt30">{{ $t('productHtml.banner1.subtitle') }}</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="swiper_link">
            <swiper :pagination="{ clickable: true }" :autoplay="{ delay: 3000, disableOnInteraction: false }"
                class="swiper_sw" :observer="true" :observeParents="true" :modules="modules" loop>
                <swiper-slide class="swiper_item" v-for="(item, index) in 3" :key="index">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60">{{ $t('productHtml.banner1.title') }}</div>
                            <div class="fs24 lh28 colf mt30">{{ $t('productHtml.banner1.subtitle') }}</div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div> -->
        <div class="product_box">
            <div class="w1760 flex">
                <div class="left_nav">
                    <div class="fs25 lh40 fwb col0">{{ $t('product.searchTitle') }}</div>
                    <div class="search_nav flex-box mb35">
                        <div class="sql"></div>
                        <input type="text" class=" flex-grow-1 fs17 col0" v-model="productVal" @keyup.enter="search"
                            placeholder="请输入关键词">
                        <img src="../assets/icon/icon_search.png" class="search_ico" @click="search" alt="">
                    </div>
                    <div class="left_link_nav">
                        <!-- <div class="left_link cp">
                            <div class="pb30 flex-box">
                                <div class=" flex-grow-1 fs19 fwb lh20 col0">工业自动化</div>
                                <img :src="scUrl" class="arrow_down" alt="">
                            </div>
                        </div> -->
                        <div class="left_link cp" v-for="(item, index) in list" :key="index">
                            <div class="pb30 flex-box" @click="change(index)">
                                <div class=" flex-grow-1 fs17 lh18 col0">{{
                                    language == 'zh' ? item.categoryName : item.categoryNameEn }}</div>
                                <img :src="item.choose ? scUrl : ucUrl" class="arrow_down" alt="">
                            </div>
                            <div class="pt8 pb30" v-if="item.childrenList && item.choose">
                                <div class="link_item flex-box" @click="chooseSub(index, i, v.categoryId)"
                                    v-for="(v, i) in item.childrenList" :key="i">
                                    <img :src="v.choose ? radioSc : radioUc" class="radio" alt="">
                                    <div class="col5a flex-grow-1 pl10">{{ language == 'zh' ? v.categoryName
                                        : v.categoryNameEn }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" flex-grow-1 right_nav">
                    <div class="right_item_a" v-for="(item, index) in productList" :key="index">
                        <router-link :to="'/ProductInfoView?id=' + item.articleId" class="right_item">
                            <img src="../assets/icon/icon_new.png" v-if="item.isNew" class="icon_new" alt="">
                            <div :style="{ backgroundImage: `url(${item.cover})` }" class="right_cover"></div>

                            <div class="text_nav">
                                <div class="m-ellipsis fwb col0 fs17 mb20">{{ language == 'zh' ? item.title :
                                    item.titleEn }}
                                </div>
                                <template v-if="language == 'zh'">
                                    <div class="content_text m-ellipsis" v-for="(item, index) in item?.articleAbstract"
                                        :key="index">
                                        {{ item }}</div>
                                </template>
                                <template v-else>
                                    <div class="content_text m-ellipsis"
                                        v-for="(item, index) in item?.articleAbstractEn" :key="index">
                                        {{ item }}</div>
                                </template>

                            </div>
                            <div class="bottom_btn_nav flex-between">
                                <router-link :to="'/ProductInfoView?id=' + item.articleId" class="btn">{{
                                    $t('product.btn2') }}</router-link>
                                <router-link :to="'/ProductInfoView?jump=true&id=' + item.articleId" class="btn cp">{{
                                    $t('product.btn1')
                                    }}</router-link>
                            </div>
                        </router-link>
                    </div>
                    <img src="../assets/icon/null_data.png" class="null" v-if="false" alt="">
                    <!-- sumCount是数据总数50条 perCount是每页有几条 pageTurn是页数切换的方法 每页显示多少条在组件pagination.vue里修改 -->
                    <pagination-w ref="pagination" :sumCount="total" :perCount="pageSize" @pageTurn="get_project">
                    </pagination-w>
                </div>
            </div>
        </div>
        <need-help></need-help>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue"
import { useHead } from '@unhead/vue'
import { useRouter } from 'vue-router'
// @ is an alias to /src
import needHelp from "../components/needHelp";
let { proxy } = getCurrentInstance()
let language = ref(localStorage.getItem('language') || 'zh');
const t = getCurrentInstance().appContext.config.globalProperties;
const banner = ref(require('../assets/icon/product_banner.jpg'));
const router = useRouter()
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
let total = ref(0)
let pageSize = ref(12)
let categoryId = ref()
let productList = ref([])
let productVal = ref()
let list = ref([]);
const scUrl = ref(require('../assets/icon/arrow_up_gray.png'))
const ucUrl = ref(require('../assets/icon/arrow_down_gray.png'))
const radioSc = ref(require('../assets/icon/radio_sc.png'))
const radioUc = ref(require('../assets/icon/radio_uc.png'))
let prod_cover = ref(require('../assets/icon/product_cover.png'))

// 列表接口
var getApiProductList = (num, size, id, key) => {
    proxy?.$api.apiProductList({ 'pageNum': num, 'pageSize': size, 'categoryIds': id, 'keywords': key }).then((res) => {
        console.log('列表', res);
        if (res.code == 200) {
            productList.value = res.rows
            productList.value.map((item) => {
                item.articleAbstract = item?.articleAbstract?.split("\n")
                item.articleAbstractEn = item?.articleAbstractEn?.split("\n")
            })
            total.value = res.total
            console.log('列表', productList.value);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
onMounted(() => {

    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
    getApiProductList(1, 12, null, null)
});
const change = ((index) => {
    list.value.map((v, i) => {
        if (i == index) {
            v.choose = !v.choose
        } else {
            v.choose = false
        }
    })
})
// 分类接口
var GetApiProductCategory = (e) => {
    proxy?.$api.apiProductCategory({}).then((res) => {
        console.log('res', res);
        if (res.code == 200) {
            list.value = res.data
            let newList = []
            console.log(list.value);
            list.value.map((item, index) => {
                // item.articleAbstract=item.articleAbstract.split("\n")
                // item.articleAbstractEn=item.articleAbstractEn.split("\n")
                newList.push(Object.assign({}, item, { choose: false }))
                if (item.childrenList) {
                    let it = []
                    list.value[index].childrenList.map((item1) => {
                        it.push(Object.assign({}, item1, { choose: false }))
                    })
                    newList[index].childrenList = it
                }
            })
            list.value = newList;
            // list.value.map((item,index)=>{
            //     item.articleAbstract=item.articleAbstract.split("\n")
            //     item.articleAbstractEn=item.articleAbstractEn.split("\n")
            // })
            list.value.map((item) => {
                item?.childrenList?.map((i, v) => {
                    console.log('i', i.parentId, categoryId.value);

                    if (i.parentId == categoryId.value) {
                        list.value.map((item) => {
                            if (item.categoryId == categoryId.value) {
                                item.choose = true
                                item?.childrenList?.map((i, v) => {
                                    i.choose = true
                                })
                            }
                        })
                    } else {
                        if (i.categoryId == categoryId.value) {
                            i.choose = true;
                            list.value.map((item) => {
                                if (i.parentId == item.categoryId) {
                                    item.choose = true
                                }
                            })
                        }
                    }
                })
            });
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
watch(() =>
    router.currentRoute.value.path,
    (toPath) => {
        if (router.currentRoute.value.query?.categoryId) {
            categoryId.value = router.currentRoute.value.query?.categoryId
            getApiProductList(1, 12, categoryId.value, null)
            // console.log(categoryId.value);
        }
        GetApiProductCategory()

    }, { immediate: true, deep: true })
const chooseSub = ((index, e, id) => {
    let catid = [];
    list.value[index].childrenList[e].choose = !list.value[index].childrenList[e].choose
    list.value.map((item, i) => {
        if (i != index) {
            item?.childrenList?.map((n, v) => {
                n.choose = false
            })
        }
    })
    list.value[index].childrenList.map((t) => {
        if (t.choose == true) {
            catid.push(t.categoryId)
        }
    })
    categoryId.value = catid.toString()
    getApiProductList(1, 12, categoryId.value, null)
})
const get_project = ((e) => {
    let page = e + 1;
    if (productVal.value != undefined) {
        getApiProductList(page, 12, null, productVal.value)
    } else if (categoryId.value != undefined) {
        getApiProductList(page, 12, null, categoryId.value)
    }
    else {
        getApiProductList(page, 12, null, null)
    }
    window.scrollTo(0, 0)
})


// 搜索
let search = () => {
    console.log(typeof productVal.value);
    getApiProductList(1, 12, null, productVal.value)
}
</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.product_box {
    width: 100%;
    padding: 50px 0 30px;
}

.left_nav {
    width: 228px;
}

.search_nav {
    margin-top: 35px;
    width: 225px;
    height: 40px;
    border-radius: 7.14px;
    border: 0.75px solid rgb(210, 210, 210);
    padding-left: 20px;
    padding-right: 16px;

    .sql {
        margin-right: 6px;
        width: 1px;
        height: 15px;
        background: rgb(114, 113, 113);
    }
}

input::placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder {
    color: rgb(114, 113, 113);
}

.mb35 {
    margin-bottom: 35px;
}

.search_ico {
    width: 16px;
    height: auto;
}

.left_link_nav {
    max-height: 530px;
    overflow-y: auto;
    width: 234px;
    overflow-x: hidden;
}

.left_link {
    padding-top: 20px;
    width: 224px;
    border-top: 0.75px solid rgb(210, 210, 210);

    .arrow_down {
        width: 12px;
        height: auto;
    }

    &:last-child {
        border-bottom: 0.75px solid rgb(210, 210, 210);
    }
}

.pt8 {
    padding-top: 8px;
}

.link_item {
    font-size: 20px;
    line-height: 20px;

    .radio {
        width: 15px;
        height: auto;
    }

    &+& {
        margin-top: 25px;
    }
}

::-webkit-scrollbar {
    width: 2px;
    background: rgb(213, 213, 213);
}

.right_nav {
    padding-left: 100px;
    white-space: wrap;

    .right_item_a {
        width: 340px;
        height: 440px;
        display: inline-block;
        vertical-align: top;
        margin-right: 20px;
        margin-bottom: 30px;
    }

    .right_item {
        width: 340px;
        height: 440px;
        border-radius: 10px;
        background: #fff;
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding-top: 30px;
        text-align: center;

        .icon_new {
            width: 68px;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }

        .text_nav {
            margin-top: 25px;
            text-align: left;
            padding: 0 20px;
            font-size: 20px;
            color: #000;
        }

        .content_text {
            position: relative;
            font-size: 13px;
            color: #5a5a5a;



            &::before {
                content: '';
                width: 5px;
                height: 5px;
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                background: #1e50e6;
                margin-right: 10px;
            }
        }

        .content_text+.content_text {
            margin-top: 5px;
        }

        .right_cover {
            width: 190px;
            height: 190px;
            margin-left: auto;
            margin-right: auto;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            transition: all 1s;
        }

        .right_cover:hover {
            background-size: 110% 110%;
        }

        .bottom_btn_nav {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
        }

        .bottom_btn_nav .btn {
            width: 50%;
            height: 68px;
            line-height: 68px;
            text-align: center;
            font-size: 15px;
            color: #1e50e6;
            border-top: 1px solid #d2d2d2;
        }

        .bottom_btn_nav .btn:last-child {
            border-left: 1px solid #d2d2d2;
        }

        .bottom_btn_nav .btn:first-child:hover {
            border-radius: 0 0 0 10px;
        }

        .bottom_btn_nav .btn:last-child:hover {
            border-radius: 0 0 10px 0;
        }
    }

}

.pr40 {
    padding-right: 50px;
}

.right_item_a:nth-of-type(4n) {
    margin-right: 0;
}
</style>
