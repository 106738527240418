<!--
  时间：2024年05月15日 14:13:00
-->
<template>
    <div class="header_fix" ref="head_fix"
        :class="[hover ? 'bg-white col0' : 'colf', isWhite ? 'bg-white col0' : 'colf']">
        <div class="w1840 head_nav flex-between flex-box">
            <router-link to="/" class="logo">
                <img src="../assets/icon/logo.png" class="logo_img" alt="">
            </router-link>
            <div class=" flex-box ml120" @mouseenter="hover = true" @mouseleave="leaveScroll">
                <template v-for="(item, index) in tab" :key="index">
                    <template v-if="index == 1">
                        <div class="tab_item cp"
                            :class="[hover ? 'col0' : 'colf', isWhite ? 'col0' : 'colf', nindex == index ? 'active' : '']"
                            @mouseenter="chooseNav(index)" @mouseleave="leaveNav()" @click="close">{{
                                item.title }}</div>
                    </template>
                    <template v-else>
                        <router-link class="tab_item" :to="item.url"
                            :class="[hover ? 'col0' : 'colf', isWhite ? 'col0' : 'colf', nindex == index ? 'active' : '']"
                            @mouseenter="chooseNav(index)" @mouseleave="leaveNav()" @click="close">{{
                                item.title }}</router-link>

                    </template>
                </template>

            </div>
            <div class="flex-box ">
                <div class="flex-box cp" @click="showPopup(6)">
                    <img :src="hover || isWhite ? globeb : globew" class="ico16" alt="">
                    <div class="fs20 fwb ml5">{{ $t('headerTabs.tab7') }}</div>
                    <img :src="hover || isWhite ? arrowb : arroww" class="ico12 ml5" alt="">
                </div>
                <!-- <div class="fs17 ml50 cp">{{ $t('headerTabs.tab8') }}</div> -->

                <img :src="hover || isWhite ? searchb : searchw" class="ico_search cp" @click="showPopup(7)" alt="">

                <div class="flex-box flex-col" :class="hover || isWhite ? 'language_b' : 'language_w'"
                    v-if="language == 'zh'">
                    <div class="cp" @click="changeLanguage('zh')">中文</div>
                    <div class="sq"></div>
                    <div class="cp" @click="changeLanguage('en')">EN</div>
                </div>
                <div class="flex-box flex-col" :class="hover || isWhite ? 'language_b' : 'language_w'"
                    v-if="language == 'en'">
                    <div class="cp" @click="changeLanguage('en')">EN</div>
                    <div class="sq"></div>
                    <div class="cp" @click="changeLanguage('zh')">中文</div>
                </div>
            </div>
        </div>
        <!-- 弹窗1  -->
        <div class="head_pop_nav" @mouseenter="chooseNav(0)" @mouseleave="leaveNav" v-if="showPop && nindex == 0">
            <div class="nav">
                <div class="fs24 lh30 fwb col5a">{{ $t('headerTabs.popuptitle1') }}</div>
                <div class="flex mt30">
                    <div class="product_list" v-for="(item, index) in productList" :key="index">
                        <router-link :to="'/ProductView?categoryId=' + item.categoryId" class="pop_title"
                            @mouseenter="choosePopItemNav(index)" @mouseleave="leaverPopItem"
                            :class="pindex == index ? 'active' : ''">{{ language == 'zh' ?
                                item.categoryName : item.categoryNameEn }}</router-link>
                        <template v-if="item?.childrenList?.length > 0">
                            <router-link :to="'/ProductView?categoryId=' + v.categoryId" class="product_item mb5"
                                :class="piindex == v ? 'active' : ''" @mouseenter="choosePopItem(index, v)"
                                @mouseleave="leaverPopItem" v-for="(v, i) in item.childrenList" :key="i">{{
                                    language == 'zh' ? v.categoryName : v.categoryNameEn }}</router-link>
                        </template>
                    </div>
                </div>
            </div>

        </div>
        <!-- 解决方案 -->
        <div class="head_pop_nav" @mouseenter="chooseNav(1)" @mouseleave="leaveNav" v-if="showPop && nindex == 1">
            <div class="nav flex-box flex-wrap">
                <router-link @click="close" v-for="(item, index) in resultList" :key="index"
                    :to="'/ResultView?id=' + item.categoryId">
                    <div class="title1">{{ language == 'zh' ? item.categoryName : item.categoryNameEn }}</div>
                </router-link>
            </div>
        </div>
        <!-- 成功案例 -->
        <div class="head_pop_nav" @mouseenter="chooseNav(2)" @mouseleave="leaveNav" v-if="showPop && nindex == 2">
            <div class="nav flex-box flex-wrap">
                <router-link @click="close" v-for="(item, index) in successList" :key="index"
                    :to="'/ExampleView?id=' + item.categoryId">
                    <div class="title1">{{ language == 'zh' ? item.categoryName : item.categoryNameEn }}</div>
                </router-link>
            </div>
        </div>
        <!-- 服务与支持 -->
        <div class="head_pop_nav n1" @mouseenter="chooseNav(3)" @mouseleave="leaveNav" v-if="showPop && nindex == 3">

            <div class="pt85">
                <div class="server_title_link flex flex-start">
                    <div class="w910 flex flex-start">
                        <router-link class="" @click="close" to="/server">
                            <div class="title1">{{ $t('support.title1') }}</div>
                        </router-link>

                        <router-link class="" @click="close" to="/DownloadView">
                            <div class="title1">{{ $t('support.title2') }}</div>
                        </router-link>
                    </div>
                    <div class="w640">
                        <img src="../assets/icon/server_img.png" class="server_img" alt="">
                    </div>
                </div>
                <!-- <div class="w640 flex-box"> -->
                <!-- <img src="../assets/icon/server_img.jpg" class="server_img" alt=""> -->
                <!-- <div class="flex-grow-1">
                        <div class="fs16 lh30 fwb col0"> {{ $t('support.subtitle1') }}</div>
                        <div @mouseover="chooseTel(0)" :class="[telIndex == 0 ? 'ac' : 'normal']">
                            <div class="cp">人工服务时间：08:00-19:00</div>
                        </div>
                        <a
                            href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=nyk&channelName=%E8%AF%BA%E9%9B%85%E5%85%8B&busitype2=%E8%AF%BA%E9%9B%85%E5%85%8B&channelType=webchat">
                            <div class="fs16 lh30 fwb col0 mt30">{{ $t('support.subtitle2') }}</div>
                            <div @mouseover="chooseTel(1)" :class="[telIndex == 1 ? 'ac' : 'normal']">
                                <div>人工服务时间：08:00-19:00</div>
                            </div>
                        </a>
                        <div class="fs14 lh30 col80 mt40">技术服务咨询欢迎发送邮件</div>
                    </div> -->

                <!-- </div> -->
            </div>
        </div>
        <div class="head_pop_nav" @mouseenter="chooseNav(5)" @mouseleave="leaveNav" v-if="showPop && nindex == 5">
            <div class="nav">
                <!-- <div class="fs24 lh30 fwb col5a">{{ $t('headerTabs.popuptitle1') }}</div> -->
                <div class="flex">
                    <div class="product_list" v-for="(item, index) in aboutList" :key="index">
                        <router-link class="pop_title" @click="close" :to="'/AboutUsView?nindex=' + index"
                            @mouseenter="choosePopItemNav(index)" @mouseleave="leaverPopItem"
                            :class="pindex == index ? 'active' : ''" v-if="index == 0 || index == 1 || index == 4">{{
                            item.title
                            }}</router-link>
                        <a v-if="index == 2" :class="pindex == index ? 'active' : ''"  @mouseenter="choosePopItemNav(index)" @mouseleave="leaverPopItem"
                            href="https://social.chint.com/social-recruitment/chint/40744/#/jobs?page=1&anchorName=jobsList&department%5B0%5D=733294"
                            class="pop_title">
                            {{ $t('about.title4') }}
                        </a>
                        <router-link v-if="index == 3" to="/ContactUsView" class="pop_title"  @mouseenter="choosePopItemNav(index)" @mouseleave="leaverPopItem"
                            :class="pindex == index ? 'active' : ''">
                            {{ $t('about.title7') }}
                        </router-link>
                        <template v-if="item.list.length > 0">
                            <router-link :to="'/AboutUsView?nindex=' + index + '&pid=' + i" class="product_item"
                                :class="piindex == v ? 'active' : ''" @mouseenter="choosePopItem(index, v)"
                                @click="close" @mouseleave="leaverPopItem" v-for="(v, i) in item.list" :key="i">{{ v
                                }}</router-link>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <popup :visible="nindex == 6" :confirmText="false" :cancleText="false" @close="closeSuc">
            <div class="head_pop_nav1">
                <div class="flex">
                    <template v-if="language == 'zh'">
                        <div class="product_list" v-for="(item, index) in siteList" :key="index">
                            <div class="pop_title">{{ item.title }}</div>
                            <template v-if="item.list.length > 0">
                                <a :href="v.url" class="product_item" :class="piindex == v ? 'active' : ''"
                                    @mouseenter="choosePopItem(index, v)" @mouseleave="leaverPopItem"
                                    v-for="(v, i) in item.list" :key="i">{{ v.name }}</a>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="product_list" v-for="(item, index) in siteListEn" :key="index">
                            <div class="pop_title">{{ item.title }}</div>
                            <template v-if="item.list.length > 0">
                                <a :href="v.url" class="product_item" :class="piindex == v ? 'active' : ''"
                                    @mouseenter="choosePopItem(index, v)" @mouseleave="leaverPopItem"
                                    v-for="(v, i) in item.list" :key="i">{{ v.name }}</a>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </popup>

        <div class="head_pop_nav flex-box flex-col flex-center" v-if="nindex == 7">
            <div class="fs40 col0 fwb">请输入关键词搜索</div>
            <div class="search_nav flex-box">
                <img src="../assets/icon/head_search_black.png" class=" ico32" alt="">
                <input type="text" class="flex-grow-1 plr20 col0 fs20" v-model="inpVal" @keyup.enter="search"
                    placeholder="请输入">
                <div class="btn cp" @click="search">搜索</div>
            </div>
        </div>

    </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, getCurrentInstance, defineProps, onUnmounted } from 'vue';
import popup from './popup.vue';
import { useRouter } from 'vue-router'
const router = useRouter()
import bus from '../utils/eventBus';
const _this = getCurrentInstance().appContext.config.globalProperties;
let { proxy } = getCurrentInstance()
let hover = ref(false);
let nindex = ref(-1);
let scrolln = ref(false);
let showPop = ref(false);
let pindex = ref(-1);
let piindex = ref(-1);
let telIndex = ref(-1)
let resultList = ref([])
let inpVal = ref([])
const props = defineProps({
    isWhite: {
        type: Boolean,
        default: false
    },
})
let language = ref(localStorage.getItem('language') || 'zh');
let tab = ref([{ url: '/ProductView', title: _this.$t('headerTabs.tab1') }, { url: '/ResultView', title: _this.$t('headerTabs.tab2') }, { url: '/ExampleView', title: _this.$t('headerTabs.tab3') }, { url: '/', title: _this.$t('headerTabs.tab4') }, { url: '/StudyView', title: _this.$t('headerTabs.tab5') }, { url: '/AboutUsView', title: _this.$t('headerTabs.tab6') }]);
const globeb = ref(require('../assets/icon/globe_black.png'));
const globew = ref(require('../assets/icon/globe_white.png'));
const arrowb = ref(require('../assets/icon/head_arrow_black.png'));
const arroww = ref(require('../assets/icon/head_arrow_white.png'))
const searchb = ref(require('../assets/icon/head_search_black.png'))
const searchw = ref(require('../assets/icon/head_search_white.png'))
let productList = ref([])
let successList = ref([])
let aboutList = ref([{
    title: _this.$t('about.title12'),
    list: [_this.$t('about.title8'), _this.$t('about.title9')]
}, {
    title: _this.$t('about.title1'),
    list: [_this.$t('about.title10'), _this.$t('about.title11')]
},
{
    title: _this.$t('about.title4'),
    list: []
},
{
    title: _this.$t('about.title7'),
    list: []
},
{
    title: _this.$t('about.title6'),
    list: []
},
])
let siteList = ref([{
    title: '智能电气',
    list: [{ name: '低压中国', url: 'https://www.chint.net/' }, { name: '正泰国际', url: 'https://chintglobal.com/' }, { name: '诺雅克', url: 'https://www.noark.cn/' }, { name: '仪器仪表', url: 'https://im.chint.com/' }, { name: '建筑电器/网络科技', url: 'https://www.chintelc.com/' }, { name: '新华科技', url: 'https://www.xinhuagroup.com/' }, { name: '物联技术', url: 'https://aiot.chint.com/' }, { name: '输配电', url: 'https://elec.chint.com/' }, { name: '电线电缆', url: 'https://cable.chint.com/' }, { name: '艾临科', url: 'http://www.elecon.cn/' }]
}, {
    title: '绿色能源',
    list: [{ name: '正泰新能源', url: 'https://energy.chint.com/' }, { name: '正泰安能', url: 'https://www.chintanneng.com/' }, { name: '正泰新能', url: 'https://www.astronergy.com.cn/' }, { name: '正泰电源', url: 'https://www.chintpower.com/' }, { name: '正泰智维', url: 'https://power.chint.com/' }]
},
{
    title: '智慧低碳',
    list: [{ name: '正泰能效', url: 'https://cee.chint.com/' }, { name: '正泰泰杰赛', url: 'http://www.tjsmc.net/' }, { name: '正泰中自', url: 'https://www.chitic.com/' }, { name: '汽车科技', url: 'http://www.chintautoparts.com/' }, { name: '一新三泰', url: 'http://www.simbatt.com/' }]
},])
let siteListEn = ref([{
    title: 'Intelligent Electric',
    list: [{ name: 'CHINT Global', url: 'https://chintglobal.com/' },
    { name: 'Noark', url: 'https://na.noark-electric.com/' },
    { name: 'CHINT Meter', url: 'http://en.chintim.com/' },
    { name: 'CHINT IoT', url: 'https://aiot.chint.com/en' }]
}, {
    title: 'Green Energy',
    list: [{ name: 'CHINT Green Energy', url: 'https://energy.chint.com/en/' },
    { name: 'CHINT Anneng', url: 'https://en.chintanneng.com/' },
    { name: 'Astronergy', url: 'https://www.astronergy.com/' },
    { name: 'CHINT POWER SYSTEMS', url: 'https://en.chintpower.com/' },
    { name: 'CHINT OM', url: 'https://power.chint.com/en' }]
},
{
    title: 'Smart Low Carbon',
    list: [{ name: 'Chitic', url: 'https://www.ichitic.com/' },
    { name: 'Chint Auto Parts', url: 'http://www.chintautoparts.com/en/' },]
},])
const showPopup = (e) => {
    if (nindex.value == e) {
        nindex.value = -1
    } else {
        nindex.value = e
    }
}
const close = () => {
    showPop.value = false
}
// 此处可打印_this查看是否存在$t方法 如果$t方法不存在 
//检查一下i18n配置globalInjection: true是否发开如果打开了仍无效 查看下方代码
const closeSuc = () => {
    nindex.value = -1
}
const em = () => {
    bus.on('brotherEvent', (data) => {
        if (data == true) {
            nindex.value = 7
        } else {
            nindex.value = -1
        }
        console.log('Received data from brother:', data)
        // 在这里可以更新组件B的状态或者执行其他业务逻辑
    })
}
onMounted(() => {
    GetApiProductCategory()
    getApiSolutionCategory()
    getapiExampleCategory()
    em()
    window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
    em()
})
onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})
const handleScroll = () => {
    // 页面滑动的距离
    let scrollTop = document.documentElement.scrollTop
    // 当页面滑动的距离大于300px时元素显示，否则不显示
    if (scrollTop >= 95) {
        hover.value = true
        scrolln.value = true
    } else {
        hover.value = false
        scrolln.value = false
    }
}
const chooseNav = ((index) => {
    nindex.value = index;
    hover.value = true;
    showPop.value = true;
});
const leaveScroll = (() => {
    if (scrolln == true) {
        return
    }
})
const leaveNav = (() => {
    nindex.value = -1;
    showPop.value = false;
})
const changeLanguage = ((e) => {
    console.log(e);
    if (e == 'zh') {
        proxy.$i18n.locale = 'zh';
        localStorage.setItem('language', 'zh')
        language.value = localStorage.getItem('language')
        history.go(0)
    }
    if (e == 'en') {
        proxy.$i18n.locale = 'en'
        localStorage.setItem('language', 'en')
        language.value = localStorage.getItem('language')
        history.go(0)
    }
})
const choosePopItemNav = ((e) => {
    pindex.value = e
})
const choosePopItem = ((i, e) => {
    pindex.value = i;
    piindex.value = e;

})
const leaverPopItem = (() => {
    pindex.value = -1;
    piindex.value = -1;
})
const chooseTel = (e) => {
    telIndex.value = e
}
const search = () => {
    if (inpVal.value == null || inpVal.value == '') {
        proxy?.$message.error('输入值不能为空');
        return
    } else {
        router.push({ path: '/SearchView', query: { 'val': inpVal.value, 'search': true } })
    }
}
// 接口调用
// 产品列表
const GetApiProductCategory = (e) => {
    proxy?.$api.apiProductCategory({}).then((res) => {
        console.log('res', res);
        if (res.code == 200) {
            productList.value = res.data

        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
// 解决方案
const getApiSolutionCategory = () => {
    proxy?.$api.apiResultCategory({}).then(res => {
        if (res.code == 200) {
            resultList.value = res.data
            console.log('解决方案', res);

        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
// 成功案例
const getapiExampleCategory = () => {
    proxy?.$api.apiExampleCategory({}).then(res => {
        if (res.code == 200) {
            successList.value = res.data

        } else {
            proxy?.$message.error(res.msg);
        }

    })
}
</script>




<style lang="less" scoped>
.w1840 {
    width: 100%;
    height: 100px;
    padding-left: 80px;
    position: relative;
}

.header_fix {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100px;
    background: transparent;
    left: 0;
    right: 0;
    top: 0;

}

.bg-white {
    background: #fff;
}

.header_fix .head_nav {
    height: 100px;
}

.logo {
    position: absolute;
    top: 0;
    left: 80px;
    width: 175px;
    height: auto;
    z-index: 99999;

    .logo_img {
        width: 175px;
        height: auto;
        z-index: 9999;
        position: relative;
    }
}

.ml120 {
    margin-left: 295px;
}

.tab_item {
    margin-right: 45px;
    font-size: 20px;
    height: 100px;
    line-height: 100px;
    position: relative;

    &.active {
        color: rgb(30, 80, 230);
    }

    &.active::after {
        content: '';
        width: 70px;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 50%;
        background: rgb(30, 80, 230);
        transform: translateX(-50%);
    }
}

.ico16 {
    width: 16px;
    height: auto;
}

.ico12 {
    width: 12px;
    height: auto;
}

.arrow {
    width: 18px;
    height: auto;
}

.ico_search {
    margin-left: 85px;
    width: 18px;
    height: auto;
}

.mr40 {
    margin-right: 40px;
}

.language_b {
    width: 60px;
    position: relative;
    margin-left: 45px;

    ::before {
        content: '';
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 2px;
        height: 24px;
        border-radius: 1px;
        background: #000;
        position: absolute;
    }

    div:first-child {
        font-size: 20px;
        color: #000;
        line-height: 20px;
    }

    .sq {
        width: 25px;
        height: 1px;
        background: rgb(145, 150, 153);
        border-radius: 0.5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    div:last-child {
        font-size: 20px;
        color: rgb(145, 150, 153);
        line-height: 20px;
    }
}

.language_w {
    width: 60px;
    position: relative;
    margin-left: 45px;

    ::before {
        content: '';
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 2px;
        height: 24px;
        border-radius: 1px;
        background: #fff;
        position: absolute;
    }

    div:first-child {
        font-size: 20px;
        color: #fff;
        line-height: 20px;
    }

    .sq {
        width: 25px;
        height: 1px;
        background: rgb(145, 150, 153);
        border-radius: 0.5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    div:last-child {
        font-size: 20px;
        color: rgb(145, 150, 153);
        line-height: 20px;
    }
}

.head_pop_nav {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    width: 100%;
    height: 450px;
    z-index: 2;
    background: #f6f6f6;

    .pt85 {
        padding-top: 85px;
        padding-left: 370px;
        padding-right: 370px;
    }

    &.n1 .pt85 {
        padding-top: 85px;
        padding-left: 370px;
        padding-right: 120px;
    }

    .nav {
        width: 100%;
        padding-left: 370px;
        padding-right: 370px;
        padding-top: 85px;
    }

    .product_list {
        margin-right: 45px;
        cursor: pointer;
    }

    .product_item {
        font-size: 20px;
        line-height: 30px;
        color: #808080;
        display: block;

        &.active {
            color: #1e50e6;
        }

        &+& {
            margin-top: 10px;
        }
    }

    .pop_title {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 30px;
        display: block;

        &.active {
            color: #1e50e6;
        }

        &.active::before {
            content: '';
            background: linear-gradient(-90deg, rgb(80, 200, 255), rgb(30, 80, 230));
            position: absolute;
            top: 50%;
            left: -15px;
            width: 7px;
            height: 21px;
            transform: translateY(-40%);
        }
    }
}

.server_title_link {
    // width: 560px;
    padding-bottom: 35px;
    color: #000;

    .w910 {
        width: 800px;
    }
}

.title1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-right: 55px;
    position: relative;
    padding-left: 15px;
    min-width: 95px;
    margin-bottom: 20px;
}

.title1:hover {
    color: #1e50e6;
}

.title1:hover::before {
    content: '';
    width: 7px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(-90deg, rgb(80, 200, 255), rgb(30, 80, 230));
}

.w640 {
    // width: 660px;
    margin-left: auto;
    margin-right: 0;

    .normal {
        font-size: 24px;
        line-height: 30px;
        color: #808080;
        margin-top: 5px;
    }

    .ac {
        font-size: 24px;
        line-height: 30px;
        color: #1e50e6;
        margin-top: 5px;
    }

    .server_img {
        width: 400px;
        height: auto;
        margin-left: 60px;
    }
}

.head_pop_nav1 {
    position: absolute;
    top: 140px;
    right: -650px;
    width: 800px;
    background: rgba(255, 255, 255, 0.9);
    z-index: 20;
    padding: 40px 80px 30px 70px;

    &:before {
        content: "";
        width: 0px;
        height: 0px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: -8px;
        right: 60px;
        opacity: .9;

    }

    .product_list {
        width: 200px;
        margin-right: 30px;

        .pop_title {
            font-size: 24px;
            font-weight: bold;
            color: #1e50e6;
        }

        .product_item {
            margin-top: 10px;
            color: #000;
            font-size: 20px;
            cursor: pointer;
            display: block;

            &.active {
                color: #1e50e6;
            }
        }
    }
}

.search_nav {
    width: 900px;
    height: 80px;
    border: 1px solid #000;
    margin-top: 80px;
    padding-left: 20px;

    .btn {
        position: relative;
        text-align: center;
        width: 180px;
        height: 80px;
        line-height: 80px;
        color: #fff;
        font-size: 20px;
        background: linear-gradient(180deg, #50c8ff, #1e50e6);
        display: block;
    }
}
</style>
