import {createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/style.css";

import { createI18n } from 'vue-i18n';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Header from "../src/components/header.vue";
import Footer from "../src/components/footer.vue";
import Fixed from "../src/components/fixed.vue";
import needHelp from "./components/needHelp.vue";
import PaginationW from './components/pagination.vue';
import en from './langurage/en';
import zh from './langurage/zh';
import * as api from "./request/api.js"; // 导入api接口
import '@/utils/rem.js'
import { createHead } from '@unhead/vue';
import AnimatedNumber from 'animated-number-vue3'
const app = createApp(App);
const messages = {
  en,
  zh
}
const i18n = createI18n({
  globalInjection: true, // 全局注册 $t方法
  legacy: false,  // 设置为 false，启用 composition API 模式
  locale: localStorage.getItem('language') || 'zh',
  messages
})
app.config.globalProperties.$t = i18n.global.t
const head = createHead();
app.use(head);
app.use(AnimatedNumber)
app.config.productionTip = false; // 关闭生产提示
app.component("Header", Header);
app.component("Footer", Footer);
app.component("Fixed", Fixed);
app.component("needHelp", needHelp);
app.component("paginationW", PaginationW);
app.use(i18n);
app.config.globalProperties.$api = api;
app.use(ElementPlus)
app.use(store);
app.use(router);
app.mount("#app");
