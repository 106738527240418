<!--
 * @author: JJ
 * @since: 2024-07-19
 * SearchView.vue
-->
<template>
    <div class="container bg-white">
        <Header :isWhite="true" />
        <div class="w1760 pt170 pb30">
            <div class="title">搜索结果</div>
            <div class="tab_nav">
                <div class="tab_item" :class="nindex == 0 ? 'active' : ''" @click="chooseNav(0)">{{ $t('headerTabs.tab1') }} ({{ total0 }})</div>
                <div class="tab_item" :class="nindex == 1 ? 'active' : ''" @click="chooseNav(1)">{{ $t('headerTabs.tab2') }} ({{ total1 }})</div>
                <div class="tab_item" :class="nindex == 2 ? 'active' : ''" @click="chooseNav(2)">{{ $t('headerTabs.tab3') }} ({{ total2 }})</div>
                <div class="tab_item" :class="nindex == 3 ? 'active' : ''" @click="chooseNav(3)">{{ $t('indexHtml.tab_text1') }} ({{ total3 }})</div>
            </div>
            <template v-if="nindex == 0">
                <div class="mt45">
                    <router-link :to="'/ProductInfoView?id=' + item.articleId" class="item flex flex-center flex-col" v-for="(item, index) in productList" :key="index">
                        <div class="t1 m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                        <div class="t2 fwb m-ellipsis"><span v-html="language == 'zh' ? item.content : item.contentEn "></span></div>
                    </router-link>
                </div>
                <pagination-w :sumCount="total0" :perCount="pageSize" @pageTurn="get_project0">
                </pagination-w>
            </template>
            <template v-if="nindex == 1">
                <div class="mt45">
                    <router-link :to="'/ResultInfoView?id=' + item.articleId" class="item flex flex-center flex-col" v-for="(item, index) in ResultList" :key="index">
                        <div class="t1 m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                        <div class="t2 fwb m-ellipsis"><span v-html="language == 'zh' ? item.content : item.contentEn "></span></div>
                    </router-link>
                </div>
                <pagination-w :sumCount="total1" :perCount="pageSize" @pageTurn="get_project1">
                </pagination-w>
            </template>
            <template v-if="nindex == 2">
                <div class="mt45">
                    <router-link :to="'/ExampleInfoView?id='+item.articleId" class="item flex flex-center flex-col" v-for="(item, index) in caseList" :key="index">
                        <div class="t1 m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                        <div class="t2 fwb m-ellipsis"> <span v-html="language == 'zh' ? item.content : item.contentEn "></span></div>
                    </router-link>
                </div>
                <pagination-w :sumCount="total2" :perCount="pageSize" @pageTurn="get_project2">
                </pagination-w>
            </template>
            <template v-if="nindex == 3">
                <div class="mt45">
                    <router-link :to="'/NewsInfoView?id='+item.articleId" class="item flex flex-center flex-col" v-for="(item, index) in NewsList" :key="index">
                        <div class="t1 m-ellipsis">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                        <div class="t2 fwb m-ellipsis"> <span v-html="language == 'zh' ? item.articleAbstract :
                  item.articleAbstractEn "></span></div>
                    </router-link>
                </div>
                <pagination-w :sumCount="total3" :perCount="pageSize" @pageTurn="get_project3">
                </pagination-w>
            </template>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, watch } from "vue"
import { useHead } from '@unhead/vue'
import { useRouter } from 'vue-router'
const router = useRouter()
let { proxy } = getCurrentInstance()
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
let nindex = ref(0);
let tab = ref(['产品中心 (15)', '解决方案 (8)', '成功案例 (3)', '公司新闻 (58)'])
let total0 = ref(30)
let total1 = ref(30)
let total2 = ref(30)
let total3 = ref(30)
let pageSize = ref(9)
let val = ref()
let page0=ref(1)
let page1=ref(1)
let page2=ref(1)
let page3=ref(1)

onMounted(() => {
    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
});

const chooseNav = (e) => {
    nindex.value = e
}
let productList = ref([])
var searchApi = (key) => {
    productApi(1,key)
    CaseApi(1,key)
    ResultApi(1,key)
    NewsApi(1,key)
}
const productApi=(num,key)=>{
    proxy?.$api.apiProductList({ 'pageNum': num, 'pageSize': 10, 'keywords': key }).then((res) => {
      
        if (res.code == 200) {
            productList.value = res.rows
            total0.value = res.total
            // console.log('列表', productList.value);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
let caseList=ref([])
const CaseApi=(num,key)=>{
    proxy?.$api.apiExampleCategoryList({ 'pageNum': num, 'pageSize': 10, 'keywords': key }).then((res) => {
       
        if (res.code == 200) {
            caseList.value = res.rows
            total2.value = res.total
            console.log('列表', caseList.value);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
let ResultList=ref([])
const ResultApi=(num,key)=>{
    proxy?.$api.apiResultListRecomd({ 'pageNum': num, 'pageSize': 10, 'keywords': key }).then((res) => {
        console.log('列表', res);
        if (res.code == 200) {
            ResultList.value = res.rows
            total1.value = res.total
            console.log('列表', ResultList.value);
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
let NewsList=ref([])
const NewsApi=(num,key)=>{
    proxy?.$api.apiNewsList({ 'pageNum': num, 'pageSize': 10, 'keywords': key }).then((res) => {
     
        if (res.code == 200) {
            NewsList.value = res.rows
            total3.value = res.total
        } else {
            proxy?.$message.error(res.msg);
        }
    })
}
const get_project0=(e)=>{
    page0.val = e + 1;
    productApi(page0.value,val.value)

}
const get_project1=(e)=>{
    page1.val = e + 1;
    ResultApi(page0.value,val.value)
}
const get_project2=(e)=>{
    page2.val = e + 1;
    CaseApi(page2.value,val.value)
}
const get_project3=(e)=>{
    page3.val = e + 1;
    NewsApi(page3.value,val.value)
}
watch(() =>
    router.currentRoute.value.path,
    (toPath) => {
        if (router.currentRoute.value.query?.val) {
            val.value = router.currentRoute.value.query?.val
            searchApi(val.value)
            // console.log(categoryId.value);
        }

    }, { immediate: true, deep: true })
</script>
<style lang="less" scoped>
.pt170 {
    padding-top: 200px;
}

.tab_nav {
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #d2d2d2;

    .tab_item {
        height: 80px;
        line-height: 80px;
        display: inline-block;
        vertical-align: top;
        font-size: 26px;
        color: #000;
        position: relative;
        margin-right: 55px;
        cursor: pointer;

        &.active {
            color: #1849ea;
        }

        &.active::after {
            content: '';
            left: 0;
            bottom: 0;
            width: 35px;
            height: 3px;
            background: #1849ea;
            position: absolute;
        }
    }
}

.item {
    height: 100px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    .t1 {
        color: #000;
        
        line-height: 20px;
        font-size: 20px;

    }

    .t2 {
        margin-top: 10px;
        font-size: 20px;
        line-height: 20px;
        color: #5a5a5a;
    }
}

.item:hover {
    padding-left: 20px;

    .t1 {
        color: #1849ea;
    }
}
</style>
