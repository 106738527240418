<!--
  时间：2024年05月23日 16:24:57
-->
<template>
  <div class="banner_link4">
    <div class="w1760">
      <div class="title">{{ $t('headerTabs.tab4') }}</div>
      <div class="mt30 fs24 lh36 col5a">{{ $t('needHelp.sub') }}</div>
      <div class="mt70 flex-box">
        <template v-for="(item, index) in serverList" :key="index">
          <template v-if="index == 0 || index == 2">
            <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat"
              class="item">
              <img :src="item.img" class="cover" alt="">
              <div class="fs24 tc fwb col0 mt10">{{ item.title }}</div>
              <div class="mt25 fs20 col0 w200" v-if="language == 'zh'">{{ item.content }}</div>
              <div class="mt25 fs15 col0 w200" v-else>{{ item.content }}</div>
              <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat"
                class="server_btn">{{ item.btn_text }}</a>
            </a>
          </template>
          <template v-else>
            <router-link :to="item.url" class="item">
              <img :src="item.img" class="cover" alt="">
              <div class="fs24 tc fwb col0 mt10">{{ item.title }}</div>
              <div class="mt25 fs20 col0 w200" v-if="language == 'zh'">{{ item.content }}</div>
              <div class="mt25 fs15 col0 w200" v-else>{{ item.content }}</div>
              <router-link :to="item.url" class="server_btn">{{ item.btn_text }}</router-link>
            </router-link>
          </template>
        </template>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue';
const t = getCurrentInstance().appContext.config.globalProperties;
let { proxy } = getCurrentInstance();
let language = ref(localStorage.getItem('language') || 'zh');
let serverList = ref([{
  img: require('../assets/icon/ic1.png'),
  title: t.$t('needHelp.title1'),
  content: t.$t('needHelp.content1'),
  btn_text: t.$t('needHelp.btn_text1'),
  url: '/SelfService'
}, {
  img: require('../assets/icon/ic2.png'),
  title: t.$t('needHelp.title2'),
  content: t.$t('needHelp.content2'),
  btn_text: t.$t('needHelp.btn_text2'),
  url: '/DownloadView'
}, {
  img: require('../assets/icon/ic3.png'),
  title: t.$t('needHelp.title3'),
  content: t.$t('needHelp.content3'),
  btn_text: t.$t('needHelp.btn_text3'),
  url: '/SelfService'
}, {
  img: require('../assets/icon/ic4.png'),
  title: t.$t('needHelp.title4'),
  content: t.$t('needHelp.content4'),
  btn_text: t.$t('needHelp.btn_text1'),
  url: '/ContactUsView'
}])
</script>

<style lang="less" scoped>
.banner_link4 {
  width: 100%;
  height: 750px;
  display: block;
  background: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 60px;

  .w200 {
    width: 350px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
  }

  .item {
    width: 415px;
    height: 400px;
    background: #e8e8e8;
    border-radius: 10px;
    padding-top: 30px;
  }

  .item+.item {
    margin-left: 27px;
  }

  .cover {
    width: 142px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.mt70 {
  margin-top: 70px;
}

.server_btn {
  width: 130px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  color: #1e50e6;
  line-height: 38px;
  position: relative;
  border-radius: 5px;
  --border-width: 3px;
  --border-radius: 10px;
  --border-color: linear-gradient(-90deg, rgba(80, 200, 255, 1), rgba(30, 80, 230, 1));
  background: transparent;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.server_btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: var(--border-width);
  border-radius: var(--border-radius);
  background: var(--border-color);
  --mask-bg: linear-gradient(red, red);
  --mask-clip: content-box, padding-box;
  -webkit-mask-image: var(--mask-bg), var(--mask-bg);
  -webkit-mask-clip: var(--mask-clip);
  -webkit-mask-composite: destination-out;
}
</style>
