<!--
 * @author: JJ
 * @since: 2024-07-22
 * NewsView.vue
-->
<template>
    <div class="container bg-f6">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60">{{ $t('newsList.title') }}</div>
                            <div class="fs24 lh44 colf mt30"><span>{{ span_text }}</span></div>
                        </div>
                        <!-- <div class="tab_nav">
                            <div class="tab_item" :class="nindex == index ? 'active' : ''" @click="chooseNav(index)"
                                v-for="(item, index) in tab" :key="index">{{ item }}</div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="news_recomd_nav">
            <div class="w1760">
                <template v-for="(item, index) in recomd" :key="index">
                    <template v-if="item.isNew == 1">
                        <a :href="item?.thirdPartyPlatformUrl" v-if="item?.thirdPartyPlatformUrl"
                        class="news_recomd_link" :style="{ backgroundImage: `url(${item.cover})` }">
                        <img src="../assets/icon/moudle.png" class=" moudle" alt="">
                        <div class="pr z2 flex flex-col flex-end news_recomd_view">
                            <div class="fs22">{{ item.updateTime }}</div>
                            <div class="fs22 mt20">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                        </div>
                    </a>
                    <router-link :to="'/NewsInfoView?id=' + item.articleId" v-else
                        class="news_recomd_link"
                        :style="{ backgroundImage: `url(${item.cover})` }">
                        <img src="../assets/icon/moudle.png" class=" moudle" alt="">
                        <div class="pr z2 flex flex-col flex-end news_recomd_view">
                            <div class="fs22">{{ item.updateTime }}</div>
                            <div class="fs22 mt20">{{ language == 'zh' ? item.title : item.titleEn }}</div>
                        </div>
                    </router-link>
                    </template>
                   
                </template>

            </div>
        </div>
        <div class="w1760 pt70 pb20">
            <template v-for="(item, index) in newsList" :key="index">
                <a :href="item?.thirdPartyPlatformUrl" class="news_item" v-if="item?.thirdPartyPlatformUrl">
                    <img :src="item.cover" class="cover" alt="">
                    <div class=" flex-grow-1 plr30">
                        <div class="fwb col0 fs22 lh35 mb10 m-ellipsis">
                            {{ language == 'zh' ? item.title : item.titleEn }}
                        </div>
                        <div class=" m-ellipsis-l2 fs15 lh25 col5a">
                            {{ language == 'zh' ? item.articleAbstract : item.articleAbstractEn }}
                        </div>
                        <div class="mt35 flex-box">
                            <div class="news_btn_mini">{{ $t('indexHtml.tab_text1') }}</div>
                            <div class=" fs15 col5a ml15">{{ item.updateTime }}</div>
                        </div>
                    </div>
                </a>
                <router-link v-else :to="'/NewsInfoView?id=' + item.articleId" class="news_item">
                    <img :src="item.cover" class="cover" alt="">
                    <div class=" flex-grow-1 plr30">
                        <div class="fwb col0 fs22 lh35 mb10 m-ellipsis">
                            {{ language == 'zh' ? item.title : item.titleEn }}
                        </div>
                        <div class=" m-ellipsis-l2 fs15 lh25 col5a">
                            {{ language == 'zh' ? item.articleAbstract : item.articleAbstractEn }}
                        </div>
                        <div class="mt35 flex-box">
                            <div class="news_btn_mini">{{ $t('indexHtml.tab_text1') }}</div>
                            <div class=" fs15 col5a ml15">{{ item.updateTime }}</div>
                        </div>
                    </div>
                </router-link>
            </template>

        </div>
        <pagination-w ref="pagination" :sumCount="total" :perCount="pageSize" @pageTurn="get_project">
        </pagination-w>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue"
import { useHead } from '@unhead/vue'
let language = ref(localStorage.getItem('language') || 'zh');
import moment from 'moment';
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
const banner = ref(require('../assets/icon/news_list_banner.jpg'));
let span_text = ref('为你提供产品技术、应用、维护相关的\n学习视频和文档，快速导入所需的知识点')
const nindex = ref(0);
const { proxy } = getCurrentInstance();
const tab = ref(['公司新闻', '媒体报道', '展会活动', '精泰视频'])
let total = ref(30)
let pageSize = ref(8)
let newsList = ref([])
let recomd = ref([])
const chooseNav = (index) => {
    nindex.value = index
}
onMounted(() => {

    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
    proxy?.$api.apiNewsList({ 'pageSize': 8, 'pageNum': 1, 'isRecommended': 0 }).then(res => {
        if (res.code == 200) {
            res.rows.map((item) => {
                item.updateTime = moment(item.updateTime).format('YYYY-MM-DD')
            })
            newsList.value = res.rows
            total.value = res.total
            console.log('新闻列表', newsList.value);
        } else {
            proxy.$message.error(res.msg);
        }
    })
    proxy?.$api.apiNewsList({ 'pageSize': 3, 'pageNum': 1, 'isRecommended': 1 }).then(res => {
        if (res.code == 200) {
            res.rows.map((item) => {
                item.updateTime = moment(item.updateTime).format('YYYY-MM-DD')
            })
            recomd.value = res.rows
            console.log('新闻列表', recomd.value);
        } else {
            proxy.$message.error(res.msg);
        }
    })
});
const get_project = ((e) => {
    let page = e + 1;
    proxy?.$api.apiNewsList({ 'pageSize': 8, 'pageNum': page, 'isRecommended': 0 }).then(res => {
        if (res.code == 200) {
            res.rows.map((item) => {
                item.updateTime = moment(item.updateTime).format('YYYY-MM-DD')
            })
            newsList.value = res.rows
            total.value = res.total
            console.log('新闻列表', newsList.value);
        } else {
            proxy.$message.error(res.msg);
        }
    })
    window.scrollTo(0, 0)
})
</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        position: relative;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.tab_nav {
    width: 1250px;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    .tab_item {
        min-width: 90px;
        height: 36px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 22px;
        color: #acacac;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
    }

    .tab_item+.tab_item {
        margin-left: 290px;
    }

    .tab_item.active {
        font-weight: bold;
        color: #fff;
    }

    .tab_item.active::after {
        content: '';
        width: 90px;
        height: 5px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.news_recomd_nav {
    width: 100%;
    height: 450px;
    background: #fff;
    padding-top: 50px;

    .news_recomd_view {
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 40px 45px;

    }

    .news_recomd_link {
        width: 500px;
        height: 350px;
        position: relative;
        z-index: 1;
        display: inline-block;
        vertical-align: top;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;

        .moudle {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 10px;
            top: 0;
            left: 0;
        }
    }

    .news_recomd_link+.news_recomd_link {
        margin-left: 130px;
    }
}

.news_item {
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    margin-top: 35px;
    margin-right: 20px;
    width: 870px;
    height: 250px;
    background: #fff;
    border-radius: 10px;

    .cover {
        width: 385px;
        height: 250px;
        border-radius: 10px 0 0 10px;
        object-fit: cover;
    }

    .news_btn_mini {
        min-width: 80px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #1e50e6;
        position: relative;
        text-align: center;
        border-radius: 10px;
        --border-width: 3px;
        --border-radius: 10px;
        --border-color: linear-gradient(-90deg, rgba(80, 200, 255, 1), rgba(30, 80, 230, 1));
        background: transparent;
        padding: 0 10px;
    }

    .news_btn_mini::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: var(--border-width);
        border-radius: var(--border-radius);
        background: var(--border-color);
        --mask-bg: linear-gradient(red, red);
        --mask-clip: content-box, padding-box;
        mask-image: var(--mask-bg), var(--mask-bg);
        -webkit-mask-image: var(--mask-bg), var(--mask-bg);
        mask-clip: var(--mask-clip);
        -webkit-mask-clip: var(--mask-clip);
        mask-composite: destination-out;
        -webkit-mask-composite: destination-out;
    }
}

.news_item:nth-of-type(2n) {
    margin-right: 0;
}
</style>
