<!--
 * @author: JJ
 * @since: 2024-07-18
 * DownloadView.vue
-->
<template>
    <div class="container bg-white">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60">{{ $t('headerTabs.tab5') }}</div>
                            <div class="fs24 lh44 colf mt30"><span>{{ span_text }}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div clss="download_nav">
            <div class="w1760 flex pt40 pb100">
                <div class="left_nav">
                    <div class="search_nav flex-box mb35">
                        <div class="sql"></div>
                        <input type="text" class=" flex-grow-1 fs17 col0" v-model="val" @keyup.enter="search"
                            placeholder="请输入关键词">
                        <img src="../assets/icon/icon_search.png" class="search_ico" @click="search" alt="">
                    </div>
                    <div class="fs25 lh40 fwb col0 mb10">资料分类</div>
                    <div class="left_link cp" v-for="(item, index) in list" :key="index">
                        <div class="pb30 flex-box" @click="change(index, item.categoryId)">
                            <div class=" flex-grow-1 fs17 lh18" :class="qindex == index ? 'col2' : 'col0'">
                                {{
                                    language == 'zh' ? item.categoryName : item.categoryNameEn }}</div>
                            <img :src="qindex == index ? scUrl : ucUrl" class="arrow_down" alt="">
                        </div>
                        <!-- <div class="pt8 pb30" v-if="item.list && item.choose">
                            <div class="link_item flex-box" @click="chooseSub(index, i)" v-for="(v, i) in item.list"
                                :key="i">
                                <img :src="v.choose ? radioSc : radioUc" class="radio" alt="">
                                <div class="col5a flex-grow-1 pl10">{{ v.name }}</div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="flex-grow-1 pl80">
                    <div class="right">
                        <div class="upload_item flex-box" v-for="(item, index) in dataList" :key="index">
                            <img src="../assets/icon/upload_cover.png" class="upload_cover" alt="">
                            <div class="name flex-grow-1 plr20 fs15 col5a">{{ language == 'zh' ? item.title :
                                item.titleEn }}</div>
                            <div class="flex-box flex-end">
                                <a :href="item.attachments" download class="upload_btn flex-box flex-center"
                                    v-if="item.allowDownload == 1">
                                    <img src="../assets/icon/up1.png" class="upload_ico1" alt="">
                                    <div>{{ $t('download.btn1') }}</div>
                                </a>
                                <a target="_blank" @click="openVideo(item.attachments)"
                                    class="upload_btn flex-box flex-center">
                                    <img src="../assets/icon/up2.png" class="upload_ico2" alt="">
                                    <div>{{ $t('download.btn2') }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <pagination-w ref="pagination" :sumCount="total" :perCount="pageSize" @pageTurn="get_project">
                    </pagination-w>
                </div>
            </div>
        </div>
        <need-help></need-help>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue"
import { useHead } from '@unhead/vue'
import axios from "axios";
const t = getCurrentInstance().appContext.config.globalProperties;
let language = ref(localStorage.getItem('language') || 'zh');
const { proxy } = getCurrentInstance();
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
const banner = ref(require('../assets/icon/download_banner.jpg'));
let span_text = ref('为你提供产品技术、应用、维护相关的\n学习视频和文档，快速导入所需的知识点')
const nindex = ref(0);
const tab = ref([t.$t('download.classify1'), t.$t('download.classify2'), t.$t('download.classify3'), t.$t('download.classify4'), t.$t('download.classify5'), t.$t('download.classify6')])
const scUrl = ref(require('../assets/icon/arrow_up_gray.png'))
const ucUrl = ref(require('../assets/icon/arrow_down_gray.png'))
let list = ref([]);
let qindex = ref('0')
let total = ref(30)
let pageSize = ref(7)
let dataList = ref([])
let val = ref()
let id = ref()
const pagination = ref()
const chooseNav = (index) => {
    nindex.value = index
    pagination.value.paging.index = 0;
}
onMounted(() => {

    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
    getCategory()
});

const download = (link) => {
    let w = window.open()
    w.location = link
}
const change = ((index, uid) => {
    qindex.value = index
    id.value = uid
    pagination.value.paging.index = 0;
    getList(7, 1, id.value, null)
})
let search = () => {
    console.log(typeof val.value);
    pagination.value.paging.index = 0;
    getList(7, 1, null, val.value)
}
const getCategory = () => {
    proxy?.$api.ApiStudyCategory({}).then(res => {
        if (res.code == 200) {
            list.value = res.data
            console.log('mie',list.value);
            id.value = list.value[0].categoryId
            getList(7, 1, list.value[0].categoryId, null)
            // let newList = []
            // console.log(list.value);
            // list.value.map((item, index) => {
            //     newList.push(Object.assign({}, item, { choose: false }))
            //     if (item.childrenList) {
            //         let it = []
            //         list.value[index].childrenList.map((item1) => {
            //             it.push(Object.assign({}, item1, { choose: false }))
            //         })
            //         newList[index].childrenList = it
            //     }
            // })
            // list.value = newList;
        } else { proxy?.$message.error(data_res.msg); }
    })
}
const getList = (size, num, id, key) => {
    proxy?.$api.ApiStudyList({ 'pageSize': size, 'pageNum': num, 'categoryId': id, 'keywords': key }).then(res => {
        if (res.code == 200) {
            console.log(res);
            dataList.value = res.rows
            total.value = res.total
            console.log(total.value);

        } else { proxy?.$message.error(data_res.msg); }
    })
}
const seeOnline = (url) => {
    // 将参数转换为查询字符串
    const queryString = `?param=${encodeURIComponent(url)}`;
    // 打开新窗口，并导航到特定的URL
    window.open(`/#/pdf${queryString}`, '_blank');
}
const openVideo = (src) => {
    var page = window.open();
    var html = "<body style='background:black'> <div style='width:80%;margin:auto;'> <video controls width='100%' height='90%' autoplay src='" + src + "'></video></div></body>"
    page.document.write(html);

}
const get_project = ((e) => {
    let page = e + 1;
    if (val.value != undefined) {
        getList(7, page, null, val.value)
    } else if (id.value != undefined) {
        getList(7, page, id.value, null)
    }
    else {
        getList(7, page, null, null)
    }

    window.scrollTo(0, 0)
})
</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        position: relative;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.tab_nav {
    width: 1760px;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    text-align: center;

    .tab_item {
        min-width: 90px;
        height: 36px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: 22px;
        color: #acacac;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
    }

    .tab_item+.tab_item {
        margin-left: 140px;
    }

    .tab_item.active {
        font-weight: bold;
        color: #fff;
    }

    .tab_item.active::after {
        content: '';
        width: 90px;
        height: 5px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.left_nav {
    width: 228px;
}

.search_nav {
    width: 225px;
    height: 40px;
    border-radius: 7.14px;
    border: 0.75px solid rgb(210, 210, 210);
    padding-left: 20px;
    padding-right: 16px;

    .sql {
        margin-right: 6px;
        width: 1px;
        height: 15px;
        background: rgb(114, 113, 113);
    }
}

input::placeholder,
input:-moz-placeholder,
input::-moz-placeholder,
input:-ms-input-placeholder {
    color: rgb(114, 113, 113);
}

.mb35 {
    margin-bottom: 35px;
}

.search_ico {
    width: 16px;
    height: auto;
}

.left_link_nav {
    max-height: 530px;
    overflow-y: auto;
    width: 234px;
    overflow-x: hidden;
}

.left_link {
    padding-top: 20px;
    width: 224px;
    border-top: 0.75px solid rgb(210, 210, 210);

    .arrow_down {
        width: 12px;
        height: auto;
    }

    &:last-child {
        border-bottom: 0.75px solid rgb(210, 210, 210);
    }
}

.pt8 {
    padding-top: 8px;
}

.link_item {
    font-size: 20px;
    line-height: 20px;

    .radio {
        width: 15px;
        height: auto;
    }

    &+& {
        margin-top: 25px;
    }
}

::-webkit-scrollbar {
    width: 2px;
    background: rgb(213, 213, 213);
}

.right {
    width: 1440px;

}

.upload_item {
    width: 1440px;
    height: 80px;
    padding-left: 70px;
    // background: #fff;
    border-radius: 5px;
    border-bottom: 1px solid #ededed;

    .name {
        min-width: 890px;
    }

    .upload_cover {
        width: 52px;
        height: auto
    }
}

// .upload_item+.upload_item {
//     margin-top: 40px;
// }

.upload_btn {
    width: 140px;
    height: 30px;
    border: 1px solid #1e50e6;
    border-radius: 5px;
    color: #1e50e6;
    font-size: 16px;
    margin-right: 60px;
}

.upload_ico2 {
    margin-right: 10px;
    width: 20px;
    height: auto;
}

.upload_ico1 {
    margin-right: 10px;
    width: 17px;
    height: auto;
}

.pl80 {
    padding-left: 80px;
}

.pb100 {
    padding-bottom: 100px;
}
</style>
