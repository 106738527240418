<!--
  时间：2024年05月24日 17:28:50
-->
<template>
  <div class="tab_nav flex-box">
    <div class="flex-grow-1">

      <div v-if="jump" :data-hash="'scroll_item' + index" :class="tabIndex == index ? 'ac item' : 'item'"
        v-for="(item, index) in tabList" @click="changeNav($event, index)" :key="index">{{ item.title }}</div>
      <div v-if="!jump" :class="tabIndex == index ? 'ac item' : 'item'" v-for="(item, index) in tabList"
        @click="changeNav($event, index)" :key="index">{{ item.title }}</div>
    </div>
    <a  href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat" class="btn_consult" v-show="show">
      <div>{{ $t('product.title8') }}</div>
      <img src="../assets/icon/arrow_right_white.png" class="ico25 ml10" alt="">
    </a>
  </div>
</template>

<script>

import { useRouter } from 'vue-router';
export default {
  // 局部注册的组件
  props: {
    tabList: {
      type: Array,
      default: [{ title: '' }]
    },
    show: {
      type: Boolean,
      default: false
    },
    jump: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  // 组件状态值
  data() {
    return {
      tabIndex: 0
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件方法
  methods: {
    scrollToAnchor(data) {
      const hash = data; // 注意：根据你的URL结构调整索引
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    changeNav(e, index) {
      this.scrollToAnchor(e.target.dataset.hash);
      
      this.tabIndex = index
      this.$emit("changeNav", index);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style lang="less" scoped>
.tab_nav {
  width: 1420px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 0 2.5px 2.5px rgba(30, 80, 230, 0.35);
  .item {
    min-width: 148px;
    height: 80px;
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 80px;
    color: #000000;
    font-size: 20px;
    border-radius: 0;
    text-align: center;
    cursor: pointer;
position: relative;
    &.ac {
      color: #1e50e6;

    }
  }
}
.ico25{width: 24px;height: auto;}
.btn_consult {
  min-width: 160px;
  padding: 0 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #1e50e6;
  color: #fff;
  font-size: 22px;
  margin-right: 40px;
  cursor: pointer;
}
.btn_consult:hover{background: #fff;color: #1e50e6;border: 1px solid #1e50e6;}
.item + .item::before{display: inline-block;vertical-align: middle;width: 1px;height: 35px;background: #5a5a5a;content: '';position: absolute;top: 50%;left: 0;transform: translateY(-50%);}
</style>
