<!--
 * @author: JJ
 * @since: 2024-07-18
 * server.vue
-->
<template>
    <div class="container bg-white">
        <Header />
        <div class="swiper_link">
            <div class="swiper_sw">
                <div class="swiper_item">
                    <div class="top_banner cu flex flex-center flex-col" :style="{ backgroundImage: `url(${banner})` }">
                        <div class="top_banner_info tl">
                            <div class="colf fs50 lh60">{{ $t('headerTabs.tab4') }}</div>
                            <div class="fs24 lh28 colf mt30">{{ $t('needHelp.sub') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w1760 pt70 pb120">
            <div class="title">{{ $t('about.title7') }}</div>
            <div class="pt120">
                <div class="tel_us_link">
                    <img src="../assets/icon/server_icon1.png" class="cover" alt="">
                    <div class="fwb fs24 lh30 col0">{{ $t('support.subtitle1') }}</div>
                    <div class="fs15 col2 mt20 lh25 fwb">400-8177-777</div>
                    <div class="fs15 col5a lh25 mt10">人工服务时间：08:00-19:00</div>
                </div>
                <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat" class="tel_us_link">
                    <img src="../assets/icon/server_icon2.png" class="cover" alt="">
                    <div class="fwb fs24 lh30 col0">{{ $t('support.subtitle2') }}</div>
                    <!-- <div class="fs15 col2 mt20 lh25 fwb"> </div> -->
                    <div class="fs15 col5a lh25 mt50">人工服务时间：08:00-19:00</div>
                </a>
                <div class="tel_us_link">
                    <img src="../assets/icon/server_icon3.png" class="cover" alt="">
                    <div class="fwb fs24 lh30 col0">{{ $t('support.subtitle3') }}</div>
                    <div class="fs15 col2 mt20 lh25 fwb">services@jingtai.com</div>
                    <div class="fs15 col5a lh25 mt10">{{ $t('support.content3') }}</div>
                </div>
            </div>
        </div>
        <div class="w1760 pt40 pb95">
            <div class="title">{{ $t('support.subtitle9') }}</div>
            <div class="pt120">
                <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat" class="item">
                    <img src="../assets/icon/server_icon1.png" class="cover" alt="">
                    <div class="fs24 lh30 fwb col0 mt20 mb50">{{ $t('support.subtitle4') }}</div>
                    <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat" class="news_btn">{{ $t('needHelp.btn_text1') }}</a>
                </a>
                <router-link to="/SingleView" class="item">
                    <img src="../assets/icon/server_icon4.png" class="cover" alt="">
                    <div class="fs24 lh30 fwb col0 mt20 mb50">{{ $t('support.subtitle5') }}</div>
                    <div class="news_btn">{{ $t('needHelp.btn_text1') }}</div>
                </router-link>
                <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat" class="item">
                    <img src="../assets/icon/server_icon2.png" class="cover" alt="">
                    <div class="fs24 lh30 fwb col0 mt20 mb50">{{ $t('support.subtitle6') }}</div>
                    <a href="https://ncsworkorde.chint.com/EClient/zt.html?mgw=0&appId=shjt&channelName=%E4%B8%8A%E6%B5%B7%E7%B2%BE%E6%B3%B0&channelType=webchat" class="news_btn">{{ $t('needHelp.btn_text1') }}</a>
                </a>
                <router-link to="/Feedback" class="item">
                    <img src="../assets/icon/server_icon5.png" class="cover" alt="">
                    <div class="fs24 lh30 fwb col0 mt20 mb50">{{ $t('support.subtitle7') }}</div>
                    <div class="news_btn">{{ $t('needHelp.btn_text1') }}</div>
                </router-link>
                <router-link :to="'/ProductView?all=' + true" class="item">
                    <img src="../assets/icon/server_icon6.png" class="cover" alt="">
                    <div class="fs24 lh30 fwb col0 mt20 mb50">{{ $t('support.subtitle8') }}</div>
                    <div class="news_btn">{{ $t('needHelp.btn_text1') }}</div>
                </router-link>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script setup>
import { ref,onMounted, getCurrentInstance } from "vue"
import { useHead } from '@unhead/vue'
let language = ref(localStorage.getItem('language') || 'zh');
let dataTitle = ref('')
let dataContent = ref('')
let keywords = ref('')
const banner = ref(require('../assets/icon/server_banner.jpg'));
onMounted(() => {
    const { proxy } = getCurrentInstance();

    // 使用全局方法
    proxy?.$api.apiBaseInfomation({}).then((data_res) => {
        let res = data_res.data
        if (data_res.code == 200) {
            if (language.value == 'zh') {
                dataTitle = res.siteName
                dataContent = res.description
                keywords = res.keywords
            } else {
                dataTitle = res.siteNameEn
                dataContent = res.descriptionEn
                keywords = res.keywordsEn
            }
            useHead({
                title: dataTitle,
                meta: [
                    { name: 'description', content: dataContent },
                    { property: 'og:title', content: dataTitle },
                    { property: 'og:description', content: dataContent },
                    { name: "keywords", content: keywords }
                ],
            })
        } else {
            proxy?.$message.error(data_res.msg);
        }
    });
});
</script>
<style lang="less" scoped>
.swiper_link {
    width: 100%;
    height: calc(100vw / 1920 * 550);
    position: relative;

    .swiper_sw {
        width: 100%;
        height: calc(100vw / 1920 * 550);
    }

    .swiper_item {
        width: 100%;
        height: 100%;
    }

    .top_banner {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }

    .top_banner_info {
        width: 1760px;
        margin-left: auto;
        margin-right: auto;
    }

    .top_btn {
        margin-top: 140px;
        width: 140px;
        height: 54px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }
}

.tel_us_link {
    width: 500px;
    height: 400px;
    background: #f6f6f6;
    display: inline-block;
    vertical-align: top;
    padding-top: 75px;
    text-align: center;
    border-radius: 10px;

    &+& {
        margin-left: 125px;
    }

    .cover {
        width: 119px;
        height: auto;
        margin-bottom: 20px;
    }

}

.pt120 {
    padding-top: 120px;
}

.pb95 {
    padding-bottom: 95px;
}

.item {
    width: 320px;
    height: 400px;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
    padding-top: 70px;
    text-align: center;
    background: #e8e8e8;

    .cover {
        width: 119px;
        height: auto;
        margin-bottom: 20px;
    }

    .news_btn {
        width: 80px;
        height: 30px;
        line-height: 26px;
        text-align: center;
        color: rgb(30, 80, 230);
        font-size: 12px;
        border: 0.0104167rem solid transparent;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-clip: padding-box, border-box;
        background-origin: padding-box, border-box;
        background-image: linear-gradient(to right, #e8e8e8, #e8e8e8), linear-gradient(-90deg, rgb(80, 200, 255), rgb(30, 80, 230));
    }
    .news_btn:hover{background: #1e50e6;color: #fff;}
}

.item+.item {
    margin-left: 40px;
}
</style>
